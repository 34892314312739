@use 'libs/styles/variables';

.typeform-modal {
  width: 80vw;
  height: 80vh;
  border-radius: 40px;
  padding: 0;
  overflow: hidden;
  background-color: variables.$color-white;

  @media (max-width: variables.$device-md) {
    width: 100vw;
    height: 86vh;
    margin-top: auto;
    margin-bottom: 0;
    border-radius: 24px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.typeform-iframe {
  flex: 1;
}
