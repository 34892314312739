@use 'libs/styles/variables';

.demo-environment-toast {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 100;
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  max-width: 24rem;
  background: variables.$color-white;

  h3 {
    @include variables.title(5);
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & > button {
    margin-left: auto;
  }

  @media (max-width: variables.$device-md) {
    width: calc(100% - 5rem);
    border-left: unset;
  }
}
