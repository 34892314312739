@use 'libs/styles/variables';
@use 'libs/styles/layout';

$b: variables.$color-purple;
$b50: rgba(variables.$color-purple, 0.5);
$b75: rgba(variables.$color-purple, 0.75);

.definition {
  z-index: 101;
  width: 25rem;
  margin: 0;
  border: 1px solid #{$b};
  border-radius: 1rem;
  padding: 0;
  background: white;
  box-shadow: #{variables.$box-shadow-medium-purple};
  transition:
    border 1.25s ease-in,
    box-shadow 1.25s ease-in,
    filter 1.25s ease-in;

  --popper-arrow-shadow-color: transparent;
  --popper-arrow-bg: url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.3887 39.8086L17.2954 31.5218C14.2804 27.4214 9.49451 25 4.40499 25L45.595 25C40.5055 25 35.7196 27.4214 32.7046 31.5218L26.6113 39.8086C25.8121 40.8956 24.1879 40.8956 23.3887 39.8086Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
  --popper-arrow-line-color: #{$b};

  & [data-popper-placement],
  [data-popper-arrow-inner] {
    transform: none !important;
    filter: drop-shadow(0 1px 0 var(--popper-arrow-line-color));
    transition:
      border 1.25s ease-in,
      box-shadow 1.25s ease-in,
      filter 1.25s ease-in;
  }

  @media (max-width: variables.$device-md) {
    width: calc(var(--chakra-sizes-xs) - 24px);
    margin-right: 12px;
    margin-left: 12px;
  }
}

.body {
  padding: 0;

  h4 {
    @include variables.title(6);
    @include layout.row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #{$b50};
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    padding: 1rem;
    padding-top: 0.675rem;
    padding-bottom: 0.5rem;
    color: white;
    background: linear-gradient(0deg, variables.$color-purple, variables.$color-org);

    svg {
      color: variables.$color-purple-dark;
      transform: translateY(0.05rem);
      opacity: 0.5;
    }
  }

  > div {
    @include layout.row(0);
    align-items: stretch;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;

    > div {
      @include layout.column(0.5rem);
      flex: 1;
      align-items: flex-start;
      padding: 0.75rem 1rem;

      p {
        @include variables.paragraph;
        flex: 1;
        line-height: 1.25;
        text-align: left;
        color: variables.$color-dark-light;

        b {
          font-weight: 500;
        }
      }
    }

    > span {
      display: inline-block;
      flex: 35% 0;
      width: 35%;
      border-left: 1px solid #{$b50};
      border-bottom-right-radius: 1rem;
      overflow: hidden;

      @media (max-width: variables.$device-md) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        border-bottom-right-radius: 1rem;
        object-fit: cover;
        object-position: right bottom;
        transition: variables.$transition-l;

        &.zoom {
          transform: scale(1.2);
          transform-origin: center center;

          &:hover {
            transform: none;
          }
        }
      }
    }
  }
}

.learn-more {
  @include variables.label;
  display: flex;
  gap: 0;
  align-items: center;
  font-weight: 500;
  text-decoration: underline;
  color: variables.$color-purple;

  svg {
    transition: variables.$transition-m;
  }

  &:hover {
    text-decoration: none;

    svg {
      transform: translate(1px, -1px);
    }
  }
}

.trigger {
  @include variables.paragraph(true);
  position: relative;
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
  font-weight: 500;
  color: variables.$color-disabled;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  path {
    fill: none;
    stroke: rgba(variables.$color-purple, 0.5);
    stroke-width: 3;
    stroke-dasharray: 12, 8;
  }

  &[data-triggered='true'] {
    path {
      animation: march 3s linear infinite;
    }
  }
}

@keyframes march {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -100;
  }
}
